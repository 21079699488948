<template>
    <div class="card">
        <div class="table-responsive">
            <table class="table nowrap">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>MR Number</th>
                        <th>Voucher Number</th>
                        <th>Invoice Number</th>
                        <th>Receipt By</th>
                        <th>Mode of Payment</th>
                        <th>Receipt Type</th>
                        <th>Patient Name</th>
                        <th>Patient ID</th>
                        <th>Mobile Number</th>
                        <th>Admission Number</th>
                        <th class="text-right">Received Amount</th>
                        <th class="text-right">Payment Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="statement in moneyReceiptStatement" :key="statement.id">
                        <td>{{ statement.receipt_date }}</td>
                        <td>{{ statement.receipt_number }}</td>
                        <td>{{ statement.voucher_number }}</td>
                        <td>{{ statement.bill_numbers }}</td>
                        <td>{{ statement.receipt_by }}</td>
                        <td>{{ statement.mode_of_payment }}</td>
                        <td>{{ statement.type }}</td>
                        <td>{{ statement.patient_name }}</td>
                        <td>{{ statement.patient_id }}</td>
                        <td>{{ statement.patient_mobile }}</td>
                        <td>{{ statement.admission_no }}</td>
                        <td class="text-right">{{ commaFormat(statement.total_paid_amount) }}</td>
                        <td class="text-right">{{ commaFormat(statement.refund_amount) }}</td>
                    </tr>
                    <tr class="bg-light-secondary">
                      <td colspan="11">Total</td>
                      <td class="text-right">{{commaFormat(totalPaidAmount)}}</td>
                      <td class="text-right">{{commaFormat(totalRefundAmount)}}</td>
                    </tr>
                    <tr class="bg-light-secondary">
                      <td colspan="12">NET COLLECTION</td>
                      <td class="text-right">{{commaFormat(totalPaidAmount - totalRefundAmount)}}</td>
                    </tr>
                </tbody>
            </table>
          <p v-if="!props.moneyReceiptStatement.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";
import {computed} from "vue";

const props = defineProps({
    moneyReceiptStatement: Array
})

const { commaFormat } = figureFormatter();

const totalPaidAmount = computed(() => {
    return props.moneyReceiptStatement.reduce((total, receipt) => total + receipt.total_paid_amount, 0);
})

const totalRefundAmount = computed(() => {
  return props.moneyReceiptStatement.reduce((total, receipt) => total + receipt.refund_amount, 0);
})
</script>

<style scoped>
    .nowrap{
        white-space: nowrap;
    }
</style>
